import axios from "axios";
const url = process.env.VUE_APP_API_URL + "api/posts/" || "api/posts/";
const urlUser = process.env.VUE_APP_API_URL + "api/user/" || "api/user/";

class PostService {
  static async getPDF(webToken) {
    try {
      const res = await axios.get(url, {
        headers: {
          "auth-token": webToken
        }
      });
      const data = res.data;
      return data;
    } catch (err) {
      return err;
    }
  }
  //Create post
  static async postActivarGarantia(datos) {
    return await axios.post(url + "activa-garantia", datos);
  }

  //Create post
  static async postGarantias(datos) {
    return await axios.post(url + "formato-garantia", datos);
  }
  static async postExperience(datos) {
    return await axios.post(url + "user-experience", datos);
  }
  //Insert images of new PDF
  static insertImages(fotos, id) {
    return axios.post(`${url}images`, { fotos, id });
  }

  //Update images of new PDF
  static async updateImages(fotos) {
    return await axios.put(`${url}images`, fotos);
  }

  static async login(user) {
    return await axios.post(`${urlUser}login`, user);
  }

  static async getGarantias(webToken) {
    return await axios.get(url + "activa-garantia", {
      headers: {
        "auth-token": webToken
      }
    });
  }

  static async getModels() {
    return await axios.get(`${url}models`);
  }

  static async getCategories() {
    return await axios.get(`${url}categories`);
  }

  static async getSpareParts(params) {
    let buildUrl = `${url}spare-parts/${params.model}`;
    if (params.category) buildUrl += `/${params.category}`;
    return await axios.get(buildUrl);
  }

  static async getFormatoGarantia(webToken) {
    return await axios.get(url + "formato-garantia", {
      headers: {
        "auth-token": webToken
      }
    });
  }

  static async getExperienciaUsuario(webToken) {
    return await axios.get(url + "user-experience", {
      headers: {
        "auth-token": webToken
      }
    });
  }

  static async subirImagen(formDataUpload) {
    return await axios.post(`${url}subir-imagen`, formDataUpload);
  }

  static async borrarRegistro(id, tabla, tipoId) {
    return await axios.post(`${url}post`, { id, tabla, tipoId });
  }
}

export default PostService;
